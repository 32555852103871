/* Fonts */
@import url('https://fonts.googleapis.com/css?family=Maitree|Work+Sans&display=swap');

/* Global settings */

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-main {
  height: 100vh;
}

.page {
  position: relative;
}

.unstyled-link,
.unstyled-link:hover {
  color: white;
}

.page-content {
  padding: 16px;
  overflow-x: hidden;
  overflow-y: scroll;
}

/* Drift CSS */

.drift-open-chat {
  display: flex;
  justify-content: flex-end;
}

.drift-open-chat:hover {
  cursor: pointer;
}

/* Public pages */
.simple-logo {
  background-image: url(./components/Content/Logo/simplelogo.png);
  background-repeat: no-repeat;
  background-size: contain;

  height: 80px;
  width: 80px;
  margin: 0 auto 40px auto;
}

#signin-page {
  height: 100%;
}



/* Private pages */
.app-header {
  z-index: 900;
  box-shadow: 0 2px 8px #6D7380;
}

.mobile-header,
.desktop-header {  
  background-color: transparent;
}

.mobile-header > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
}

.mobile-header .back-button {
  display: inline-block;
}

.mobile-header .back-button button,
.mobile-header button.back-button {
  color: white;
  border: none;
}

.mobile-header .octave-logo {
  font-size: 26px;
  margin-right: 8px;
}

.user-avatar {
  text-align: center; 
}

.logo {
  background-image: url(./components/Content/Logo/logo-name.svg);
  background-repeat: no-repeat;
  background-size: contain;
  height: 32px;
  width: auto;
  margin: 0;
}

.mobile-drawer {
  z-index: 2000;
}

.mobile-drawer .mobile-menu {
  display: flexbox;
  justify-content: center;
  margin-top: 48px;
}

.mobile-drawer .mobile-menu .octave-menu {
  margin-top: 32px;
  border: none;
}

.mobile-drawer .mobile-menu .sign-out-button {
  position: absolute;
  bottom: 48px;
  left: 25%;
}


.app-main .ant-layout-sider {
  transition: none;
}

/* Main text styles */

.mobile-header #page-title {
  font-family: 'Work sans', sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: white;
}

h2 {
  font-family: 'Work sans', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #6d7380;
}

.page-content h2 {
  margin-bottom: 16px;
}

/* Onboarding styles */

.onboarding-page .page-content {
  padding: 0;
}

.onboarding-page .page-cover .cover-img {
  width: 100%;
  max-width: 1024px;
  height: auto;
  padding-top: 56.25%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;

  /* create a trapezoid shape for the div */
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 75%); 
}

.onboarding-page .page-cover .progress-bar {
  height: 8px;
  width: 100%;
  background-color: #fbfbfb;
  display: flex;
  flex-direction: row;
}

.onboarding-page .page-cover .progress-bar .active {
  height: 100%;
  width: 33.3333333%;
  background-color: #008DD5;
}

.onboarding-page .page-cover .progress-bar .inactive {
  height: 100%;
  width: 30%;
}

.onboarding-page .content-header {
  padding: 0 24px;
}

.onboarding-page .content {
  padding: 0 24px;
}

.onboarding-page .content p {
  font-size: 16px;
}

.onboarding-page .content li {
  margin-bottom: 8px;
}

/* Home page styles */
.empty-planning {
  border-radius: 10px;
  border: 1px dashed #dadcdf;
  background-color: #FBFBFB;
  margin: 0 8px 24px 8px;
  padding: 16px 24px;
  text-align: center;
}

.empty-planning svg {
  display: block;
  width: 65%;
  height: auto;
  margin: 0 auto 16px auto;
}

.planning-section {
  margin-bottom: 32px;
}

.category-link::before {
  content: "> ";
}

.category-link:hover {
  cursor: pointer;
  text-decoration-line: underline;
}

.category-link:active {
  cursor: pointer;
  text-decoration-line: underline;
}


.recipes-list {
  margin-bottom: 24px;
}

.recipes-list > div {
  display: flex;
  width: 100%;
  overflow-x: scroll;
}

.recipes-list .recipe {
  flex-shrink: 0; /* guarantees that the cards keep their width */
  width: 200px;
  margin: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.recipes-list .recipe {
  flex-shrink: 0; /* guarantees that the cards keep their width */
  width: 200px;
  margin: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.recipe div.cover {
  position: relative;
  display: flex;
  width: 100%;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.recipe .recipe-title {
  font-size: 12px;
  display: block;
  margin: 0 0 2px 0;
}

/* Menu creation page */
#menu-creation .illustration {
  margin-top: 48px;
  margin-bottom: 48px;
}

#menu-creation .helper {
  font-size: 18px;
  padding-left: 16px;
}

/* Main content */
.page-content .main-funnel-button {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 342px;
  height: 150px;
  background-color: #6CC566;
  border-radius: 10px;
  margin: 0 auto 24px auto;
  padding: 16px;
  color: #fff;

  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
}

.page-content .content-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page-content .content-header h1 {
  margin-bottom: 24px;
  color: #373F51;
  font-family: 'Maitree', serif;
  font-size: 20px;
  font-weight: 600;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-content .content-header .content-header-action {
  display: flex;
  justify-content: flex-end;
}

.page-content .content-header .content-header-action .edit-action {
  margin-bottom: 24px;
  font-size: 20px;
  text-align: right;
}

.page-content .content-header .content-header-action .edit-action button {
  padding: 0;
  color: #373F51;
  font-size: 20px;
}

.page-content .organize {
  background: linear-gradient(284.75deg, #6CC566 73.54%, rgba(255, 255, 255, 0) 73.55%), url(./components/Content/Buttons/bg-organize.jpg), #6CC566;
}

.page-content .cook {
  background: linear-gradient(284.75deg, #6CC566 73.54%, rgba(255, 255, 255, 0) 73.55%), url(./components/Content/Buttons/bg-cook.jpg), #6CC566;
}

.page-content .shop {
  background: linear-gradient(284.75deg, #6CC566 73.54%, rgba(255, 255, 255, 0) 73.55%), url(./components/Content/Buttons/bg-shop.jpg), #6CC566;
}

.page-content p {
  font-size: 16px;
  text-align: justify;
  line-height: 152%;
  letter-spacing: -1%;
}

.page-content .main-funnel-button h3 {
  font-family: 'Maitree', serif;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  line-height: 214%
}

.page-content .main-funnel-button p {
  font-size: 14px;
  line-height: 140%;
  text-align: right;
  width: 75%;
}

/* Buttons */
.drawer-button {
  border: none;
  background: none;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.page-content .form-input-button {
  margin: 32px auto 0 auto;
  padding: 0 42px;
}

.placeholder {
  margin-top: 64px;
  margin-bottom: 32px;
}

.placeholder #ph-planning {
  width: 168px;
  height: 168px;
  background: no-repeat center url(./components/Content/Buttons/ph-greencal.png), #fff;
  border: 1px #6CC566 solid;
  border-radius: 50%;
  margin-bottom: 32px;
}

.octave-form {
  text-align: center; /* to align the submit button in form */
}

/* Toolbars */
.page-content .withToolbar {
  margin-bottom: 80px;
}

.main-toolbar {
  height: 72px;
  width: 100%;
  z-index: 900;
  background-color: #FBFBFB;
  padding: 0;
  margin: 0;
  box-shadow: 0 -1px 8px #6D7380;
  /* in order to have the shadow above other elements, position is set to relative */
  position:relative;
}

.funnel-toolbar {
  position: absolute;
  bottom: 0;
  left:0;
  height: 72px;
  width: 100%;
  z-index: 900;
  box-shadow: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
}

.recipe-matcher-content .funnel-toolbar {
  bottom: 8px;
}

.main-toolbar .toolbar-action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  height: 32px;
  width: auto;
}

.main-toolbar .toolbar-action:hover {
  cursor: pointer;
}

.main-toolbar .toolbar-action .button-add button {
  height: 48px;
  width: 48px;
  font-size: 32px;
}

.main-toolbar .toolbar-action .button-add button path {
  fill: white;
  color: white;
}

.main-toolbar .toolbar-action path {
  fill: #6D7380;
  color: #6D7380;
}

.main-toolbar .active path { /* apply the color to the path rather than the svg - I don't know why it doesn't work */
  fill: #008DD5;
  color: #008DD5;
}

.main-toolbar .inactive path {
  fill: #6D7380;
  color: #6D7380;
}

.main-toolbar .disabled path {
  fill: #dadcdf;
  color: #dadcdf;
}

.main-toolbar .inactive:hover {
  cursor: pointer;
}

.page-content .menu-toolbar {
  position: absolute;
  top: 0;
  left: 0;
  height: 64px;
  width: 100%;
  z-index: 1000;
  padding: 0;
  margin: 0;
  background-color: #8BCBEB;
  box-shadow: 0 4px 4px #6D7380;
}

.menu-toolbar .toolbar-action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  height: 24px;
  margin-bottom: 16px;
  width: auto;
}

main.page-content .menu-toolbar div.toolbar-action:hover {
  cursor: pointer;
}

.menu-toolbar .toolbar-action path {
  fill: white;
  color: white;
}

.menu-toolbar .active path {
  fill: #008DD5;
  color: #008DD5;
}

.funnel-toolbar .toolbar-action {
  display: flex;
  flex-direction: column;
  box-shadow: none;
  /* height: 32px; */
  width: auto;
}

.funnel-toolbar .right-action  {
  align-items: flex-end;
  padding-right: 32px;
}

.funnel-toolbar .left-action  {
  align-items: start;
  padding-left: 32px;
}

.mini-toolbar {
  height: 40px;
  width: 100%;
  box-shadow: none;
  background-color: transparent;
  padding: 0;
  margin: 16px 0 0 0;
  display: flex;
  justify-content: space-around;
  align-content: center;
  border-top: 1px solid #008DD5;
}

.mini-toolbar .toolbar-action {
  width: 25%;
  padding: 10px 0;
  text-align: center;
}

.mini-toolbar .toolbar-action:hover {
  cursor: pointer;
}

.mini-toolbar .toolbar-action:not(:last-child) {
  border-right: 1px solid #008DD5;
}

.mini-toolbar .toolbar-action svg {
  height: 100%;
  width: auto;
}

.mini-toolbar .toolbar-action svg path {
  fill: #373F51;
  color: #373F51;
}

/* Menu Scheduling */
#schedule .previous-button {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
}

#schedule .next-button {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
}

#schedule .schedule-day {
  padding: 0 16px;
}

#schedule .schedule-day h2 {
  text-align: center;
  padding-bottom: 16px;
  font-weight: 600;
  /* border-bottom: 1px solid #6CC566; */
}

#schedule .schedule-day .schedule-meals .lunch,
#schedule .schedule-day .schedule-meals .dinner {
  margin: 16px 0;
  min-height: 120px;
}

#schedule .schedule-day .schedule-meals .category {
  padding: 8px 16px;
  width: 100%;
  margin-bottom: 16px;
  /* color: #6D7380; */
  color: white;
  background-color: #008DD5;
  font-family: 'Maitree', serif;
  font-weight: 600;
}

#schedule .schedule-day .schedule-meals .meal-selection {
  /* text-align: center;
  padding: 16px 8px; */

  margin: 8px;
  padding: 16px 8px;
  text-align: center;
  font-size: 12px;
  border-radius: 10px;
  border: 1px dashed #dadcdf;
  background-color: #fbfbfb;
}

#schedule .schedule-day .schedule-meals .meal-selection p {
  font-size: 12px;
  text-align: center;
}

.scheduled-meal,
.schedulable-meal {
  border: 1px solid #dadcdf;
  border-radius: 10px;
  padding: 24px 8px 8px 8px;
  margin-bottom: 16px;
  position: relative;
}

.schedulable-meal:hover {
  cursor: pointer;
}

.scheduled-meal .action,
.schedulable-meal .action {
  position: absolute;
  top: 4px;
  right: 0;
  width: 24px;
  height: 24px;
  text-align: center;
}

.scheduled-meal .action:hover,
.schedulable-meal:hover .action {
  color: #EE6C4D;
  cursor: pointer;
}

.recipe-preview {
  margin: 0 24px 24px 16px;
}

.scheduled-meal .recipe-preview,
.schedulable-meal .recipe-preview {
  margin: 0 0 16px 0;
}

.scheduled-meal .recipe-preview:hover,
.schedulable-meal .recipe-preview:hover {
  cursor: pointer;
}

.recipe-preview .thumbnail {
  border-radius: 5px;
  width: 100%;
  padding-top: 75%;
  background-repeat: no-repeat;
  background-size: cover;
}

.recipe-preview .recipe-title {
  font-size: 12px;
  font-weight: 600;
}

/* Menu card */
/* head and body styles are also available in the card code directly */

.last-planning .menu-card,
.planning-list .menu-card {
  border-radius: 10px;
  border: 1px solid #008DD5;
}

.planning-list .menu-card {
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.25);
}

/* on HP */
.last-planning .menu-card {
  margin-bottom: 16px;
}

.menu-card .card-header-link {
  font-weight: bold;
  color: white;
}

.metrics {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.meal-counter .metrics {
  padding: 16px 0;
  margin-bottom: 16px;
  border-bottom: 2px solid #008DD5;
}

/* .metrics .lunches .value, */
.metrics .metric .value {
  font-size: 20px;
}

.meal-counter .metrics .metric .value {
  font-size: 22px;
  font-weight: bold;
}

.metrics .metric span {
  display: block;
  text-align: center
}

.metrics .metric .value {
  color: #008DD5;
}

.metrics .metric .label {
  color: #373F51;
}


/************* Menu list ***************/
  



/************* Menu detail *************/
/* TODO : introduce media queries for responsive design */
.planning-schedule,
.planning-new,
.planning-validated,
.planning-edit,
.menu-dinners,
.menu-lunches {
  padding-top: 64px;
}

.planning-empty {
  padding-top: 48px;
}

#menu .meal-counter {
  /* background-color: #BCE4B9; */
  margin-bottom: 24px;
}

#menu .meal-counter--empty {
  margin-bottom: 16px;
}

#menu .meal-counter--empty svg {
  display: block;
  width: 80%;
  height: auto;
  margin: 0 auto 24px auto;
}

#menu .meal-counter .counter {
  height: 112px;
  width: 112px;
  margin: 0px auto;

  background-color: #008dd5;
  border-radius: 56px;

  color: white;
  font-family: 'Maitree', serif;
  font-size: 16px;
  font-weight: bold;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Meal section */

.meals-section {
  margin-bottom: 24px;
}

.meals-section .meal-card--empty,
.schedule-nomeal .meal-card--empty {
  border-radius: 10px;
  border: 1px dashed #dadcdf;
  background-color: #FBFBFB;
  margin: 0 16px 24px 16px;
  padding: 16px 24px;
  text-align: center; 
}

.meals-section .meal-card--empty h3,
.schedule-nomeal .meal-card--empty h3 {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 8px
}

.meals-section .meal-card--empty p,
.schedule-nomeal .meal-card--empty p {
  padding: 0;
  margin: 0 0 8px 0;
  font-size: 14px;
}

.meals-section .meal-card--empty button,
.schedule-nomeal .meal-card--empty button {
  font-family: 'Work sans', sans-serif;
  font-weight: 500;
  color: #008DD5;
  padding: 0;
}

.schedule-day .schedule-meals .schedule-nomeal {
  margin: 8px;
  padding: 16px 8px;
  text-align: center;
  font-size: 12px;
  border-radius: 10px;
  border: 1px dashed #dadcdf;
  background-color: #fbfbfb;
}

.schedule-day .schedule-meals .schedule-nomeal p {
  font-size: 12px;
  text-align: center;
}

/* style for the header and the body of the card is also defined directly in the component */

.meals-list > div {
  display: flex;
  padding: 8px 0 8px 0;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
}

.meals-list .meal-card {
  position: relative;
  flex-shrink: 0; /* guarantees that the cards keep their width */
  width: 264px;
  margin: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 48px;
}

.meal-card div.cover {
  position: relative;
  display: flex;
  width: 100%;
  height: 144px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.meal-card .cover .starter,
.meal-card .cover .main {
  width: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.meal-card .meal-title {
  font-size: 12px;
  display: block;
  margin: 0 0 2px 0;
}

.meal-card .starter-title::before,
.meal-card .main-title::before {
  display: inline-block;
  /* border-bottom: 2px solid #6D7380; */
  margin-right: 8px;

  font-family: 'Maitree', serif;
  font-weight: bold;

  color: #6D7380;
}

.meal-card .starter-title::before {
  content: "Entrée";
}

.meal-card .main-title::before {
  content: "Plat";
}

.meal-card .cover .meal-settings {
  width: 100%;
  height: 40px;
  padding: 0 8px;
  
  position: absolute;
  bottom: 0;
  left: 0;
  
  background-color: rgb(255, 255, 255, 0.85);
  
  font-size: 14px;
  font-weight: bold;

  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-items: center; */
  justify-content: space-between;
}

.meal-card .ant-card-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.meal-card .ant-card-actions .critical {
  color: #EE6C4D;
}

.meal-card .ant-card-actions .critical:hover {
  color: #EE6C4D;
}

.meal-list-item div.recipe-pic {
  position: relative;
  display: flex;
  width: 100%;
  padding-top: 75%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* Meal detail */

.meal .recipe-description img {
  width: 100%;
  margin-bottom: 16px;
}

/* Recipe detail */

.recipe-idcard img {
  max-width: 100%;
  margin-bottom: 16px;
}

.recipe-idcard .short-description {
  font-size: 14px;
  text-align: left;
}

ol.steps-list {
  counter-reset: step;
  padding: 0;
}

ol.steps-list li {
  list-style-type: none;
  counter-increment: step;
  margin-bottom: 16px;
}

ol.steps-list li::before {
  content: "Etape " counter(step);
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #6CC566;
}


ul.ingredients-list  {
  list-style: none;
  padding-left: 16px;
}

ul.ingredients-list  li {
  margin-bottom: 8px;
}

ul.ingredients-list  li .name::after {
  content: " : ";
}

ul.ingredients-list  li .quantity {
  margin-right: 4px;
}

/* Planning choice form styling */

/* TODO : turn the number of meals input into something nicer */

.funnel-page .page-content {
  display: flex;
  margin-top: 32px;
  padding-top: 0;
  padding-bottom: 0;
}

.funnel-page .funnel-header {
  position: fixed;
  background-color: white;
  z-index: 900;
  width: 100%;
}

.funnel-page .funnel-header .label-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.funnel-page .funnel-header .label-container span {
  font-size: 12px;
  margin: 0 0 0 8px;
}


.funnel-page .funnel-header .close-button-container {
  display: flex;
  justify-content: flex-end;
}

.funnel-page .close-button-container .close-funnel--button {
  border: none;
  box-shadow: none;
}

.funnel-page .close-button-container .close-funnel--button:hover {
  color: #EE6C4D;
}

.recipe-matcher-content {
  flex-grow: 1;
  display: flex;
}

.recipe-matcher--button {
  border: none;
  border-radius: 36px;
  height: 72px;
  width: 72px;
  text-align: center;
  vertical-align: middle;
  font-size: 36px;
  margin: 0 8px;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25); 
}

.recipe-matcher--button:active {
  box-shadow: inset 4px 4px 6px rgba(0, 0, 0, 0.25);
}

.match--button {
  color: white;
  background-color: #6CC566;
}

.discard--button {
  color: white;
  background-color: #EE6C4D;
}

.next--button {
  color: white;
  background-color: #008DD5;
}

.rewind--button {
  color: #008DD5;
  background-color: white;
}

.recipe-card {
  flex-grow: 1;
  border: 1px solid #6D7380;
  border-radius: 10px;
  padding: 8px 16px;
  margin-bottom: 8px;
  width: 100%;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25); 
  overflow-y: scroll;
}

.recipe-card h3 {
  font-weight: 600;
  text-align: center;
}

.recipe-card div.recipe-img {
  width: 100%;
  padding-top: 56.25%;
  border-radius: 10px;
  margin: 8px auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; 
}

.recipe-card p {
  font-size: 12px;
  line-height: 1.5;
  text-align: left;
}

.recipe-card ul {
  padding: 0px;
  column-count: 2;
  column-rule: solid 1px #6D7380;
  list-style-type: none;
}

.recipe-card ul li {
  margin-left: 8px;
  font-size: 12px;
}

.recipe-card h4 {
  font-weight: bold;
  font-size: 14px;
  color: #6CC566;
  font-family: 'Maitree', serif;
}

.recipe-card .recipe-labels {
  margin-bottom: 16px;
}

.recipe-card .recipe-labels .difficulty {
  text-align: right;
}

.recipe-card .recipe-labels .difficulty span {
  text-transform: capitalize;
}

.recipe-card .title {
  font-size: 22px;
  font-weight: 600;
  font-family: 'Maitree', serif;
  text-align: center;
}

.recipe-card .matched p,
.recipe-card .discarded p {
  text-align: center;
  font-size: 14px;
}

.recipe-card .matched .title {
  color: #6CC566;
}

.recipe-card .discarded .title {
  color: #EE6C4D;
}